// Fonts
@font-face {font-family: 'SourceSansPro-Regular'; font-style: normal; font-weight: 400;
     src: local('SourceSansPro-Regular'),
     url('/fonts/SourceSansPro-Regular.eot?#iefix') format('embedded-opentype'),
     url('/fonts/SourceSansPro-Regular.woff2') format('woff2'),
     url('/fonts/SourceSansPro-Regular.ttf') format('truetype'),
     url('/fonts/SourceSansPro-Regular.svg#svgSourceSansPro-Regular') format('svg')
}
@font-face {font-family: 'SourceSansPro-Semibold'; font-style: normal; font-weight: 400;
     src: local('SourceSansPro-Semibold'),
     url('/fonts/SourceSansPro-Semibold.eot?#iefix') format('embedded-opentype'),
     url('/fonts/SourceSansPro-Semibold.woff2') format('woff2'),
     url('/fonts/SourceSansPro-Semibold.ttf') format('truetype'),
     url('/fonts/SourceSansPro-Semibold.svg#svgSourceSansPro-Semibold') format('svg')
}
@font-face {font-family: 'SourceSansPro-Bold'; font-style: normal; font-weight: 400;
     src: local('SourceSansPro-Bold'),
     url('/fonts/SourceSansPro-Bold.eot?#iefix') format('embedded-opentype'),
     url('/fonts/SourceSansPro-Bold.woff2') format('woff2'),
     url('/fonts/SourceSansPro-Bold.ttf') format('truetype'),
     url('/fonts/SourceSansPro-Bold.svg#svgSourceSansPro-Bold') format('svg')
}
@font-face {font-family: 'SourceSansPro-Black'; font-style: normal; font-weight: 400;
     src: local('SourceSansPro-Black'),
     url('/fonts/SourceSansPro-Black.eot?#iefix') format('embedded-opentype'),
     url('/fonts/SourceSansPro-Black.woff2') format('woff2'),
     url('/fonts/SourceSansPro-Black.ttf') format('truetype'),
     url('/fonts/SourceSansPro-Black.svg#svgSourceSansPro-Black') format('svg')
}

// Colors
$pickled-bluewood: #2b3749;
$mid-gray: #5e5f63;
$cararra: #f2f1ed;
$picton-blue: #49b1de;
$solitude: #e9f3fe;
$big-stone: #17253a;
$slate-gray: #727e8f;
$mischka: #d5dae1;
$concrete: #f2f2f2;
$cadet-blue: #b6bcc6;
$sham-rock: #4cde8c;
$cabaret: #de4670;
$di-serria: #de974b;
$royal-blue: #4676dd;
$viking: #4bc4de;
$medium-purple: #904add;
$cello: #214f6d;
$alto: #d1d1d1;
$cerulean: #00a2dc;
$mandy: #e95369;
$atlantis: #96bf31;

//fonts-family
$regular: 'SourceSansPro-Regular', 'Helvetiva', sans-serif;
$semibold: 'SourceSansPro-Semibold', 'Helvetiva', sans-serif;
$bold: 'SourceSansPro-Bold', 'Helvetiva', sans-serif;
$black: 'SourceSansPro-Black', 'Helvetiva', sans-serif;

//fonts-size
$font-size-base: 18px !default;

$h1-size: floor(($font-size-base * 1.85)) !default;
$h2-size: floor(($font-size-base * 1.373)) !default;
$h3-size: ceil(($font-size-base * 1.2)) !default;
$h4-size: ceil(($font-size-base * 1.02)) !default;
$h5-size: ceil(($font-size-base * 1)) !default;
$h6-size: ceil(($font-size-base * 1)) !default;

// Spacing
$s-size: 1.4rem;
$m-size: 1.6rem;
$l-size: 2.8rem;
$xl-size: 4.8rem;
$desktop-breakpoint: 45rem;
