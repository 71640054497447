/* ==|====================
   Layout/Nav
   ======================= */
aside {
  position: relative;
  width: 26rem; height: 100vh;
  float: left;
  background: $cello;
  content: '';
  z-index: 10;
  .logo {
    position: relative;
    margin: 6rem auto 5rem auto;
    display: table;
    max-width: 13rem;
    filter: invert(100%) brightness(200%);
    z-index: 9;
  }
}
ul.menu {
  position: relative;
  margin: 2rem 0 0 0;
  z-index: 3;
  li {
    position: relative;
    margin-bottom: 1rem;
    display: block;
    list-style: none;
    width: 100%;
    user-select: none;
    &.listed-property a::before {background: url('/icons/home.svg') no-repeat}
    &.property a::before {background: url('/icons/add-property.svg') no-repeat}
    &.settings a::before {background: url('/icons/settings.svg') no-repeat}
    &.about a::before {background: url('/icons/about.svg') no-repeat}
    &.agenda a::before {background: url('/icons/agenda.svg') no-repeat}
    &.sensors a::before {
      background: url('/icons/sensors.svg') no-repeat;
      background-size: 2.6rem;
      width: 2.6rem; height: 2.6rem;
      left: 2.7rem;
    }
    &.documents a::before {background: url('/icons/documents.svg') no-repeat; left: 3.3rem;}
    &.reports a::before {background: url('/icons/reports.svg') no-repeat; left: 3.3rem;}
    &.contact a::before {background: url('/icons/contact.svg') no-repeat; top: 1.3rem; filter: invert(100%)}
    &.logout a::before {background: url('/icons/logout.svg') no-repeat; top: 1.1rem;}
    &.additional-services {
      a::before {
        display:flex;
        align-items: center;
        justify-content: center;
        top: 1.2rem; left: 3.2rem;
        background-color: white;
        width: 1.6rem; height: 1.6rem;
        border-radius: .2rem;
        font-size: 2.2rem;
        font-family: $regular;
        text-align: center;
        color: $cello;
        content: '+'
      }
      &:hover a::before {filter: none; background-color: $viking}
    }
    a::before {
      position: absolute;
      top: 1rem; left: 3rem;
      background-size: 1rem;
      width: 2rem; height: 2rem;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      pointer-events: none;
      z-index: 2;
      content: '';
    }
    a {
      user-select: none;
      display: block;
      padding: 1.2rem 0 1.2rem 7rem;
      color: white;
      font-family: $semibold;
      font-size: 1.4rem;
      line-height: 1.2;
      text-decoration: none;
      text-transform: uppercase;
      pointer-events: auto;
      z-index: 4;
      &.is-active {
        color: $viking;
      }
    }
    &.contact:hover a::before, &.contact a.is-active::before {
      filter: invert(100%) brightness(43%) sepia(4) saturate(10) hue-rotate(150deg)
    }
    &:hover, a.is-active {
      a {color: $viking; background: transparent}
      a::before {filter: brightness(43%) sepia(4) saturate(10) hue-rotate(150deg)}
      &::before {filter: brightness(43%) sepia(4) saturate(10) hue-rotate(150deg)}
    }
    &:hover::after, &.is-active::after {
      position: absolute;
      margin-top: -3rem;
      right: 0;
      width: 0; height: 0;
      border-style: solid;
      border-width: 1rem 1rem 1rem 0;
      border-color: transparent $cararra transparent;
      content: '';
    }
  }
}

#btn-menu-mobile {
  position: fixed;
  display: none;
  padding: 1rem;
  top: 1.6rem; right: 3rem;
  width: 2rem; height: 1.2rem;
  cursor: pointer;
  z-index: 999;
  span {
    display: block;
    position: absolute;
    height: .2rem;
    width: 100%;
    background: white;
    opacity: 1;
    left: 0;
    transition: all .5s cubic-bezier(0.045, 0.715, 0.250, 1.000);
    &:nth-child(1) {top: 0}
    &:nth-child(3) {top: .8rem}
    &:nth-child(2) {top: 1.6rem}
  }
  &.open {
    span {background: white}
    span:nth-child(1) {
      top: .8rem;
      transform: rotate(135deg)
    }
     span:nth-child(2) {
      top: .8rem;
      transform: rotate(-135deg);
      width: 100%
    }
    span:nth-child(3) {
     top: .8rem;
     transform: rotate(-135deg);
     width: 100%
   }
  }
}

@media only screen and (max-width: 768px) {
  aside {
    position: fixed;
    top: 0;
    width: 100%; height: 5rem;
    float: none;
    pointer-events: none;
    .logo {
      margin: .8rem auto 0 auto;
      max-width: 8.6rem;
      pointer-events: auto;
    }
  }
  ul.menu {
    margin: -100vh 0 0 0;
    padding: 4rem 1rem 0 1rem;
    width: 100vw;
    background-color: $cello;
    transition: all .25s cubic-bezier(0.045, 0.715, 0.250, 1.000);
    overflow: hidden;
    &.open {height:100vh; overflow: hidden; margin: .7rem 0 0 0; border-top: .1rem solid #5a7b91;}
    li {
      &:hover::after, &.is-active::after {display: none}
    }
  }
  #btn-menu-mobile {
    display: block;
    pointer-events: auto;
  }
}
