@font-face{
  font-family:'HaloHandletter';
  src:url('/assets/fonts/HaloHandletter.woff2') format("woff2"),
      url('/assets/fonts/HaloHandletter.woff') format("woff");
      font-weight:400;
      font-style:normal;
}
@font-face{
  font-family:'Inter-Regular';
  src:url('/assets/fonts/Inter-Regular.woff2') format("woff2"),
      url('/assets/fonts/Inter-Regular.woff') format("woff");
      font-weight:400;
      font-style:normal;
}
@font-face{
  font-family:'Inter-Medium';
  src:url('/assets/fonts/Inter-Medium.woff2') format("woff2"),
      url('/assets/fonts/Inter-Medium.woff') format("woff");
      font-weight:400;
      font-style:normal;
}
@font-face{
  font-family:'Inter-Bold';
  src:url('/assets/fonts/Inter-Bold.woff2') format("woff2"),
      url('/assets/fonts/Inter-Bold.woff') format("woff");
      font-weight:400;
      font-style:normal;
}
@font-face {
  font-family: 'Font-icons';
  src: local('Font-icons'),
     url('/assets/fonts/Font-icons.eot?#iefix') format('embedded-opentype'),
     url('/assets/fonts/Font-icons.woff2') format('woff2'),
     url('/assets/fonts/Font-icons.ttf') format('truetype'),
     url('/assets/fonts/Font-icons.svg#svgFont-icons') format('svg');
     font-style: normal;
     font-weight: 400;
}

.icon:before {
	font-family: "font-icons";
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	font-style: normal;
	font-variant: normal;
	font-weight: normal;
	/* speak: none; only necessary if not using the private unicode range (firstGlyph option) */
	text-decoration: none;
	text-transform: none;
}


.icon-facebook:before {
	content: "\E001";
}

.icon-googleplus:before {
	content: "\E002";
}

.icon-instagram:before {
	content: "\E003";
}

.icon-linkedin:before {
	content: "\E004";
}

.icon-pinterest:before {
	content: "\E005";
}

.icon-twitter:before {
	content: "\E006";
}

.icon-viadeo:before {
	content: "\E007";
}

.icon-vine:before {
	content: "\E008";
}

.icon-youtube:before {
	content: "\E009";
}
