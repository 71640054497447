/* ==|====================
   Module/Boutons
   ======================= */
button, button:hover, .btn {
  cursor: pointer;
  transition: all .25s cubic-bezier(0.045, 0.715, 0.250, 1.000);
}
input[type='file'] {
  appearance: button;
  user-select: none;
  outline: none;
}
.btn-remove {
  position: relative;
  top: 50%; float: right;
  transform: translateY(50%);
  background-image: url('/icons/close.png');
  background-size: 1rem;
  width: 1rem; height: 1rem;
  cursor: pointer;
  filter: invert(50%) sepia(1) hue-rotate(140deg) saturate(4);
  &:hover {filter: brightness(118%) invert(50%) sepia(1) hue-rotate(140deg) saturate(4)}
}
.box-save-cancel {
  position: absolute;
  top: 9.5rem;
  right: -16rem;
  padding: .5rem 0 .5rem .5rem;
  width: 9rem;
  background: #e1e1e1;
  box-shadow: inset .4rem 0 .5rem 0 rgba(0,0,0,0.2);
  z-index: 9;
  button {
    margin: 0 -.2rem 0 1rem;
    border-radius: 50%;
    padding: 0;
    &:hover {filter:brightness(110%);}
    &::before {
      position: absolute;
      cursor: pointer;
      content: '';
      filter: brightness(500%)
    }
    &:first-child {
      min-height: 2.5rem; height: 2.5rem; width: 2.5rem;
      background-color: $mandy;
      &::before {
        top: .4rem; right: .5rem;
        background-image: url('/icons/close.png');
        background-size: 1.5rem;
        width: 1.5rem; height: 1.5rem;
        filter: invert(100%)
      }
    }
    &:last-child {
      min-height: 3rem; height: 3rem; width: 3rem;
      background-color: $atlantis;
      &::before {
        top: .5rem; right: .5rem;
        background-image: url('/icons/check.png');
        background-size: 2rem;
        width: 2rem; height: 2rem;
      }
    }
  }
}
button, .btn, input[type='file'] {
  position: relative;
  margin-top: 1rem;
  min-height: 4rem; width: auto;
  padding: 0 2rem;
  font-size: 1.4rem;
  line-height: 4rem;
  font-family: $black;
  text-align: center;
  border-radius: .3rem;
  text-decoration: none;
  text-transform: uppercase;
  box-shadow: none;
  background: $solitude;
  color: $picton-blue;
  outline: none;
  cursor: pointer;
  &.btn-primary, &.btn-secondary, &.btn-third, &.btn-fourth, &.btn-fifth, &.btn-sixth, &.btn-seventh {
    padding: 0 1rem;
    min-height: 3rem;
    line-height: 3rem;
    background: transparent;
    border: .2rem solid $solitude;
    &:hover {
      color: $solitude;
      background: $picton-blue;
      border-color: $picton-blue;
    }
  }
  &.btn-primary {
    background: $picton-blue;
    border-color: $picton-blue;
    color: white;
    a {color: white}
    &:hover {
      color:$picton-blue;
      background: $solitude;
      border-color: $solitude;
      a {color: $picton-blue;}
    }
  }
  &.btn-secondary {
    min-height: 2.7rem;
    line-height: 2.7rem;
    border: .2rem solid $solitude;
    &.contact {padding: 0; display: block}
    a {
      min-height: 2.7rem;
      line-height: 2.7rem;
      padding: 0 1rem;
      display: block;
      color: $picton-blue;
    }
    &:hover a {color: white}
    &.cabaret {
      border-color: $cabaret;
      color: $cabaret;
      &:hover {background: $cabaret; color: white}
    }
  }
  &.btn-third {
    margin-top: -3.5rem;
    border-color: $solitude;
  }
  &.btn-fourth {
    padding: 0 .8rem;
    font-size: 1.2rem;
    background: $solitude;
    border-color: $solitude;
  }
  &.btn-fifth {
    background: $solitude;
    color: $picton-blue;
    font-size: 1.2rem;
    min-height: 3.5rem;
    line-height: 3.5rem;
    border: none;
  }
  &.btn-sixth {
    background: $picton-blue;
    color: $solitude;
    border: none;
    font-size: 1.4rem;
    padding: 0 2rem;
    &:hover {
      color: $picton-blue;
      background: $solitude;
    }
  }
  &.btn-seventh {
    padding: 0 2.3rem 0 1rem;
    min-height: 3.1rem;
    line-height: 3.1rem;
    border: .2rem solid $solitude;
    &::after {
      position: absolute;
      margin: .9rem auto 0 auto;
      right: .2rem;
      background-image: url('/icons/download.svg');
      background-repeat: no-repeat;
      background-size: .9rem;
      width: 1.5rem; height: 1.5rem;
      content: '';
    }
    &:hover::after {filter: brightness(500%);}
  }
  &.btn-eighth {
    background: $solitude;
    color: $picton-blue;
    font-size: 1.4rem;
    min-height: 3.5rem;
    line-height: 3.5rem;
    border: none;
    &:hover {
      color: $solitude;
      background:  $picton-blue;
    }
  }
  &.btn-add-element-room {
    margin: -3rem 0 0 0;
    float: right;
    min-height: 2.3rem;
    line-height: 2.3rem;
    border: .1rem solid $picton-blue;
    background: white;
    font-size: 1.2rem;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  &.btn-edit {
    padding: 0;
    width: 3.5rem; min-height: 3.5rem;
    line-height: 3.5rem;
    &::before {
      position: absolute;
      margin: -.7rem auto 0 auto;
      left: 0; right: 0;
      background-image: url('/icons/pen-b.svg');
      background-repeat: no-repeat;
      background-size: 1.5rem;
      width: 1.5rem; height: 1.5rem;
      content: '';
      filter: invert(100%) brightness(45%) sepia(1) hue-rotate(140deg) saturate(4)
    }
    &:hover {
      background: $picton-blue;
      &::before {filter: invert(100%) brightness(645%)}
    }
  }
  &.btn-prev, &.btn-next {
    background: $picton-blue;
    min-height: 3.5rem; width: 3.5rem;
    padding: 0;
    line-height: 3.5rem;
    &:before {
      position: absolute;
      margin: -1rem auto 0 auto;
      left: -.7rem; right: 0;
      background-image: url('/icons/arrow.svg');
      background-repeat: no-repeat;
      background-size: 2rem;
      width: 2rem; height: 2rem;
      content: '';
      transform: rotate(90deg);
      filter: invert(100%) brightness(80%);
    }
    &:hover {background: $picton-blue;}
    &.solitude {
      background: $solitude;
      &:hover {background: $picton-blue;}
    }
    &.cararra {
      background: $cararra;
      &:hover, &.checked {background: $solitude;}
      &.checked::before {filter: invert(100%) brightness(50%) sepia(1) hue-rotate(140deg) saturate(4)}
      &:hover::before, &.checked:hover::before {filter: invert(100%) brightness(55%) sepia(1) hue-rotate(140deg) saturate(4)}
    }
  }
  &.btn-next {
    &::before {left: .9rem; transform: rotate(-90deg)}
  }
  &.btn-prev {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  &.btn-next {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}
a.btn.btn-sixth {padding-top: .5rem; padding-bottom: .5rem}
